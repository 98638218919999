.tns-outer [aria-controls], .tns-outer [data-action] {
  display: none;
}

.section .heading {
  text-align: center;
  color: #2f7c72;
  margin: 0 0 5px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.2;
}

.section .sub-heading {
  text-align: center;
  margin: 0 0 40px;
  font-size: 2rem;
  line-height: 1.2;
}

@media only screen and (width <= 1180px) {
  .section .heading {
    font-size: 4.4rem;
  }
}

@media only screen and (width <= 767px) {
  .section .heading {
    font-size: 2.6rem;
  }

  .section .sub-heading {
    font-size: 1.6rem;
  }
}

header nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header nav .logo-wrap {
  max-width: 260px;
  padding: 12px 0 0;
}

.logo-wrap a {
  justify-content: center;
  align-items: center;
  display: flex;
}

.globe-wrap {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: flex;
}

.globe-svg {
  fill: #529471;
  transform-origin: 50%;
  animation: 10s linear infinite rotate;
}

.logo-content {
  border-left: 3px solid #529471;
}

.logo-content .bold {
  color: #529471;
  margin-bottom: 10px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.2;
}

.logo-content p {
  color: #529471;
  padding: 0 0 0 10px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

header nav button {
  color: #e5f1e3;
  background: #529471;
  border-radius: 50px;
  width: 200px;
  height: 55px;
  padding: 10px;
  font-size: 24px;
  font-weight: 700;
}

@media only screen and (width <= 767px) {
  header nav .logo-wrap {
    margin: 0 auto;
  }
}

.banner {
  align-items: center;
  min-height: 710px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.banner-bg {
  z-index: 0;
  background-image: linear-gradient(105deg, #ffffffe6 0% 50%, #0000 65%), url("banner.b94a1033.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.banner .container {
  z-index: 1;
  align-items: center;
  gap: 30px;
  padding: 40px 16px;
  display: flex;
}

.banner .left-wrap .title {
  color: #2f7c72;
  max-width: 615px;
  margin-bottom: 20px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.banner .left-wrap .desc {
  max-width: 632px;
  font-size: 2rem;
  line-height: 1.7;
}

.banner .left-wrap .offer-wrap {
  background-color: #fff;
  border: 4px solid #2f7c72;
  border-radius: 20px;
  margin: 20px 0;
  padding: 10px;
  position: relative;
}

.banner .left-wrap .offer {
  color: #081b37;
  font-family: poppins-medium;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1;
}

.banner .right-wrap {
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

.banner .right-wrap .form-bg {
  z-index: 0;
  opacity: .8;
  background: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.banner .right-wrap:hover .form-bg {
  opacity: 1;
}

.banner .right-wrap .form-wrap {
  z-index: 1;
  padding: 20px;
  position: relative;
}

.banner .right-wrap .title {
  color: #2f7c72;
  margin-bottom: 5px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.2;
}

.banner .right-wrap .desc {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.2;
}

.banner .right-wrap .field-block {
  margin-bottom: 30px;
}

.banner .right-wrap .field-block label {
  text-transform: capitalize;
  color: #000;
  font-size: 16px;
  line-height: 1.1;
}

.banner .right-wrap .field-block label span {
  margin-bottom: 6px;
  display: block;
}

.form__input {
  background: #efefef;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  transition: all .5s ease-out;
  display: block;
}

select {
  appearance: none;
}

.selected {
  background: #efefef;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  transition: all .5s ease-out;
  display: block;
}

.field-block .input-wrap {
  position: relative;
}

.field-block .icon-wrap {
  pointer-events: none;
  padding: 0 0 8px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 15px;
  right: 12px;
}

.form-wrap .date {
  box-sizing: border-box;
  border: none;
  outline: 0;
  width: 100%;
  padding: .75rem;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #0000;
  cursor: pointer;
  background: none;
  width: auto;
  height: auto;
  position: absolute;
  inset: 0;
}

.form__input:focus {
  background: #efefef;
  border-bottom: 3px solid #529471;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.form__input:focus:invalid {
  border-bottom: 3px solid red;
}

.banner .form-wrap .submit_button {
  color: #fff;
  background: #529471;
  border-radius: 50px;
  width: 100%;
  padding: 10px;
  font-family: poppins-medium, Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
}

@media only screen and (width <= 1180px) {
  .banner .left-wrap .title {
    font-size: 4.4rem;
  }
}

@media only screen and (width <= 1030px) {
  .banner .container {
    flex-direction: column;
    gap: 20px;
    padding: 20px 16px;
  }

  .banner-bg {
    background-image: linear-gradient(#ffffffe6 0% 50%, #0000 65%), url("banner.b94a1033.jpg");
    background-position: top;
  }

  .banner .left-wrap {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .banner .left-wrap .offer-wrap {
    margin: 8px 0;
    padding: 6px;
  }

  .banner .left-wrap .title, .banner .left-wrap .desc {
    text-align: center;
  }

  .banner .right-wrap {
    margin-left: 0;
  }

  .banner .right-wrap .title {
    font-size: 2rem;
  }

  .banner .right-wrap .desc {
    margin-bottom: 18px;
    font-size: 1.6rem;
  }

  .banner .form-wrap .submit_button {
    font-size: 1.8rem;
  }

  .banner .right-wrap .form-bg {
    opacity: 1;
  }
}

@media only screen and (width <= 767px) {
  .banner .left-wrap .title {
    font-size: 2.6rem;
  }

  .banner .left-wrap .desc {
    font-size: 1.6rem;
  }
}

.faq-wrapper {
  padding: 60px 0;
  position: relative;
}

.faq-wrapper .heading {
  margin: 0 0 20px;
}

.faq-wrapper .offer {
  color: #081b37;
  margin: 0 0 25px;
  font-family: poppins-medium;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1;
}

.faq-wrapper .heading:before {
  content: "";
  background: #ff8600;
  width: 67px;
  height: 4px;
  position: absolute;
  top: -8px;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  margin-bottom: 16px;
}

.accordion-item .table-data {
  border-bottom: 1px solid #e3dfdf;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  display: flex;
}

.accordion-item .table-data:last-child {
  border: none;
}

.accordion-item .table-data p {
  color: #081b37;
  font-size: 1.6rem;
  line-height: 1.1;
}

.accordion-item .table-data p:first-child {
  margin: 0 10px 0 0;
  font-family: poppins-medium;
  font-weight: 500;
}

.accordion-item .accordion-button {
  cursor: pointer;
  text-align: left;
  color: #1b3459;
  background: #fff;
  border-radius: 16px;
  outline: none;
  width: 100%;
  padding: 16px 40px 16px 16px;
  font-family: poppins-medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  transition: all .4s;
  position: relative;
}

.accordion-item .accordion-button:after {
  content: "+";
  color: #8b94a1;
  height: 30px;
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.accordion-item .accordion-button.active:after {
  content: "−";
  color: #8b94a1;
  font-weight: 400;
}

.accordion-item .accordion-body {
  background-color: #fff;
  border-radius: 16px;
  max-height: 0;
  padding: 0 18px;
  transition: max-height .2s ease-out;
  overflow: auto hidden;
}

.accordion-item .accordion-button.active + .accordion-body {
  margin-bottom: 16px;
}

.accordion-item .accordion-button .accordion-body {
  padding: 0;
}

.faq-wrapper .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-wrapper .desc {
  color: #000;
  text-align: center;
  margin: 30px 0;
  font-size: 2.2rem;
  line-height: 1.1;
}

.pricing-cta {
  background: #529471;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
}

.pricing-cta .text {
  color: #fff;
  font-family: poppins-medium, Arial, Helvetica, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1;
}

.pricing-cta .arrow-anim-wrapper {
  width: 30px;
  height: 26px;
  margin-left: 8px;
  position: relative;
  overflow: hidden;
}

.pricing-cta .arrow {
  border: 2px solid #fff;
  border-color: #fff #fff #0000 #0000;
  width: 8px;
  height: 8px;
  animation: 1.2s linear infinite slide;
  position: absolute;
  top: 10px;
  left: 8px;
  transform: translateX(0)rotate(45deg);
}

.pricing-cta .cta:hover .arrow {
  border-color: #fff #fff #0000 #0000;
}

.pricing-cta .delay1 {
  animation-delay: 0s;
}

.pricing-cta .delay2 {
  animation-delay: .4s;
}

.pricing-cta .delay3 {
  animation-delay: .8s;
}

@media only screen and (width <= 767px) {
  .faq-wrapper {
    padding: 30px 0 60px;
  }

  .faq-wrapper .heading {
    margin: 0 0 30px;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-10px)rotate(45deg);
  }

  20% {
    opacity: 1;
    transform: translateX(-7px)rotate(45deg);
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 1;
    transform: translateX(7px)rotate(45deg);
  }

  100% {
    opacity: 0;
    transform: translateX(10px)rotate(45deg);
  }
}

.features {
  background: #f4f8ff;
  padding: 100px 0;
}

.features .list-wrap {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -18px;
  display: flex;
}

.features .list-wrap .list-item {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 33.3333%;
  margin: 0 0 20px;
  padding: 0 18px;
  display: flex;
}

.features .list-wrap .list-item .title {
  text-align: center;
  color: #2f7c72;
  margin-bottom: 10px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.features .list-wrap .list-item .desc {
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.7;
}

@media only screen and (width <= 1080px) {
  .features .list-wrap .list-item {
    max-width: 50%;
  }
}

@media only screen and (width <= 767px) {
  .features {
    padding: 60px 0;
  }

  .features .list-wrap .list-item {
    max-width: 100%;
  }

  .features .list-wrap .list-item .title {
    font-size: 1.6rem;
  }

  .features .list-wrap .list-item .desc {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}

.cars {
  padding: 80px 0;
}

.cars .container {
  max-width: 1100px;
}

.cars #controls {
  z-index: 9;
  align-items: center;
  gap: 20px;
  margin-bottom: -50px;
  display: flex;
  position: relative;
}

.cars .slider-control {
  border: 1px solid #0000;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.cars .slider-control:hover {
  border-color: #529471;
}

.cars .slider-control svg {
  width: 80%;
}

.cars .tns-outer {
  margin: 0 auto;
}

.cars .img-wrap {
  max-width: 600px;
  margin: 0 auto;
}

.cars .slide .title {
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.5;
}

@media only screen and (width <= 767px) {
  .cars {
    padding: 60px 0;
  }

  .cars .sub-heading {
    margin-bottom: 0;
  }

  .cars #controls {
    margin-bottom: 0;
    display: none;
  }

  .cars .slide .title {
    font-size: 2rem;
  }

  .cars .tns-outer {
    margin: 24px auto 0;
  }
}

.about {
  color: #fff;
  background: #40413c;
  margin: 0;
  padding: 80px 0;
}

.about .heading {
  margin-bottom: 40px;
}

.about .content-wrap {
  gap: 20px;
  width: 100%;
  display: flex;
}

.about .content-wrap .left-wrap, .about .content-wrap .right-wrap {
  max-width: 50%;
}

.about .left-wrap {
  border-radius: 20px;
  overflow: hidden;
}

.about .right-wrap {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.about .right-wrap .title {
  margin-bottom: 30px;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.about .right-wrap .desc {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
}

@media only screen and (width <= 1080px) {
  .about {
    padding: 60px 0;
  }

  .about .content-wrap {
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
  }

  .about .content-wrap .left-wrap, .about .content-wrap .right-wrap {
    max-width: 100%;
  }

  .about .right-wrap {
    padding: 0;
  }

  .about .right-wrap .title {
    margin-bottom: 20px;
    font-size: 2.6rem;
  }

  .about .right-wrap .desc {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

footer {
  background-color: #f3f7ec;
  padding: 80px 0 40px;
}

footer .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

footer .img-wrap {
  width: 320px;
  margin-top: -20px;
}

footer .right-wrap li {
  margin-bottom: 10px;
  font-size: 1.6rem;
  line-height: 1.2;
}

footer .right-wrap li a:hover {
  text-decoration: underline;
}

footer .copyright {
  text-align: center;
  margin-top: 40px;
  font-size: 1.6rem;
  line-height: 1;
}

@media only screen and (width <= 767px) {
  footer {
    padding: 20px 0;
  }

  footer .container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  footer .img-wrap {
    width: 150px;
    margin: 0 auto;
  }

  footer .right-wrap li {
    text-align: center;
  }

  footer .copyright {
    font-size: 1.4rem;
  }
}
/*# sourceMappingURL=index.06bd9355.css.map */
