/* common */
.tns-outer [aria-controls], .tns-outer [data-action]{display: none;}
.section .heading {
  font-size: 4.8rem;
  line-height: 1.2;
  font-weight: 700;
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 0 5px 0;
  color: #2f7c72;
}
.section .sub-heading {
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 40px 0;
}

@media only screen and (max-width: 1180px) {
  .section .heading {
    font-size: 4.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .section .heading {
    font-size: 2.6rem;
  }
  .section .sub-heading {
    font-size: 1.6rem;
  }
}
/* common */

/* header */
header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header nav .logo-wrap {
  max-width: 260px;
  padding: 12px 0 0 0;
}
.logo-wrap a{display: flex;align-items: center;justify-content: center;}
.globe-wrap{width: 80px;height: 80px;border-radius: 50%;display: flex;align-items: center;justify-content: center;}
.globe-svg { fill: #529471; transform-origin: 50% 50%; animation: rotate 10s linear infinite; } 
.logo-content{border-left: 3px solid #529471;} 
.logo-content .bold{font-family: "poppins-bold", Arial, Helvetica, sans-serif; font-weight: 700; font-size: 2.8rem; line-height: 1.2;margin-bottom: 10px;color: #529471;} 
.logo-content p{font-size: 1.8rem; line-height: 1.2;color: #529471;font-family: "poppins-bold", Arial, Helvetica, sans-serif; font-weight: 700;padding: 0 0 0 10px;}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header nav button {
  width: 200px;
  height: 55px;
  padding: 10px;
  background: #529471;
  color: #e5f1e3;
  border-radius: 50px;
  font-size: 24px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  header nav .logo-wrap {
    margin: 0 auto;

  }
}
/* header */

/* banner */
.banner {
  min-height: 710px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.banner-bg {
  z-index: 0;
  background-image: linear-gradient(
      105deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 50%,
      transparent 65%
    ),
    url("./../images/banner.jpg");
  /* filter: blur(2px); */
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner .container {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 40px 16px;
  gap: 30px;
}
.banner .left-wrap .title {
  max-width: 615px;
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 5.6rem;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #2f7c72;
}

.banner .left-wrap .desc {
  max-width: 632px;
  font-size: 2rem;
  line-height: 1.7;
}

.banner .left-wrap .offer-wrap {
  position: relative;
  border: 4px solid #2f7c72;
  border-radius: 20px;
  margin: 20px 0;
  padding: 10px; 
  background-color: white; 
}
.banner .left-wrap .offer {
  font-size: 2.2rem;
  line-height: 1.1;
  font-family: "poppins-medium";
  font-weight: 500;
  color: #081b37;
}





.banner .right-wrap {
  width: 100%;
  max-width: 500px;
  position: relative;
  border-radius: 20px;
  margin-left: auto;
  overflow: hidden;
}
.banner .right-wrap .form-bg {
  z-index: 0;
  background: #fff;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease;
}
.banner .right-wrap:hover .form-bg {
  opacity: 1;
}

.banner .right-wrap .form-wrap {
  z-index: 1;
  position: relative;
  padding: 20px;
}
.banner .right-wrap .title {
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #2f7c72;
}

.banner .right-wrap .desc {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 30px;
}
.banner .right-wrap .field-block {
  margin-bottom: 30px;
}
.banner .right-wrap .field-block label {
  font-size: 16px;
  line-height: 1.1;
  text-transform: capitalize;
  color: #000;
}
.banner .right-wrap .field-block label span {
  display: block;
  margin-bottom: 6px;
}
.form__input {
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  border: none;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  background: #efefef;
  transition: all 0.5s ease-out;
}
select{
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none; 
}
.selected{font-size: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  border: none;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  background: #efefef;
  transition: all 0.5s ease-out;}
 .field-block .input-wrap{position: relative;}
 .field-block .icon-wrap {
    position: absolute;
    top: 15px;
    right: 12px;
    transition: all .3s ease-in-out;
    padding: 0 0 8px 0;
    pointer-events: none;
}
.form-wrap .date {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #529471;
  background: #efefef;
}
.form__input:focus:invalid {
  border-bottom: 3px solid red;
}
.banner .form-wrap .submit_button {
  width: 100%;
  padding: 10px;
  font-size: 2.4rem;
  font-weight: 500;
  font-family: "poppins-medium", Arial, Helvetica, sans-serif;
  border-radius: 50px;
  background: #529471;
  color: #fff;
}
@media only screen and (max-width: 1180px) {
  .banner .left-wrap .title {
    font-size: 4.4rem;
  }
}

@media only screen and (max-width: 1030px) {
  .banner .container {
    padding: 20px 16px;
    flex-direction: column;
    gap: 20px;
  }
  .banner-bg {
    background-image: linear-gradient(180deg, #ffffffe6 0% 50%, #0000 65%),
      url(./../images/banner.jpg);
    background-position: top;
  }
  .banner .left-wrap{display: flex;flex-direction: column;align-items: center;}
  .banner .left-wrap .offer-wrap{margin: 8px 0;padding: 6px;}
  .banner .left-wrap .title,
  .banner .left-wrap .desc {
    text-align: center;
  }

  .banner .right-wrap {
    margin-left: 0;
  }
  .banner .right-wrap .title {
    font-size: 2rem;
  }
  .banner .right-wrap .desc {
    font-size: 1.6rem;
    margin-bottom: 18px;
  }
  .banner .form-wrap .submit_button {
    font-size: 1.8rem;
  }
  .banner .right-wrap .form-bg {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .banner .left-wrap .title {
    font-size: 2.6rem;
  }
  .banner .left-wrap .desc {
    font-size: 1.6rem;
  }
}

/* banner */

  /* accordion */
  .faq-wrapper {position: relative; padding: 60px 0 60px 0;}
  .faq-wrapper .heading{margin: 0 0 20px 0;}
  .faq-wrapper .offer{  font-size: 2.2rem;line-height: 1.1;font-family: "poppins-medium"; font-weight: 500;    color: #081b37;margin: 0 0 25px 0;}
  .faq-wrapper .heading::before {content: ""; width: 67px; height: 4px; background: #ff8600; position: absolute; top: -8px;}
  .accordion-item {background-color: #fff; border: 1px solid #D9D9D9; border-radius: 16px; margin-bottom: 16px;}
  .accordion-item .table-data{display: flex;align-items: center; justify-content: space-between;border-bottom: 1px solid #e3dfdf;padding: 10px 0;}
  .accordion-item .table-data:last-child{border: none;}
  .accordion-item .table-data p{ font-size: 1.6rem; line-height: 1.1;  color: #081B37;}
  .accordion-item .table-data p:first-child{margin: 0 10px 0 0;font-weight: 500;font-family: "poppins-medium"; }
  .accordion-item .accordion-button {background: #fff; cursor: pointer; padding: 16px 40px 16px 16px; width: 100%; text-align: left; outline: none; transition: 0.4s; border-radius: 16px; position: relative; font-size: 18px; line-height: 26px; font-family: "poppins-medium"; font-weight: 500; color: #1B3459;}
  .accordion-item .accordion-button:after {content: '\002B'; color: #8B94A1; position: absolute; top: 50%; right: 16px; transform: translateY(-50%); font-weight: 400; font-size: 24px; height: 30px;}
  .accordion-item .accordion-button.active:after {content: "\2212"; color: #8B94A1; font-weight: 400;}
  .accordion-item .accordion-body {padding: 0 18px; background-color: #fff; max-height: 0; overflow: hidden; border-radius: 16px; transition: max-height 0.2s ease-out; overflow-x: auto;}
  .accordion-item .accordion-button.active + .accordion-body {margin-bottom: 16px;}
  .accordion-item .accordion-button .accordion-body {padding: 0;}
  .faq-wrapper .accordion-item:last-child {margin-bottom: 0;}

 
  .faq-wrapper .desc{font-size: 2.2rem;line-height: 1.1;color: #000; text-align: center; margin: 30px 0;}
  .pricing-cta{max-width: 300px;display: flex;align-items: center;justify-content: center;padding: 16px;  background: #529471;margin: 0 auto;border-radius: 50px;}
  .pricing-cta .text{font-size: 2.2rem;line-height: 1.1;  font-weight: 500;font-family: "poppins-medium", Arial, Helvetica, sans-serif;color: #fff;}
   .pricing-cta .arrow-anim-wrapper{position:relative;width:30px;height:26px;overflow: hidden;margin-left: 8px;} 
   .pricing-cta .arrow {position: absolute;top: 10px;left: 8px;-webkit-animation: slide 1.2s linear infinite; animation: slide 1.2s linear infinite;width: 8px;height: 8px;border: 2px solid;border-color:  white white transparent transparent;transform: translateX(0) rotate(45deg);}
   .pricing-cta .cta:hover .arrow {border-color: #FFF #FFF transparent transparent; } 
   .pricing-cta .delay1 {-webkit-animation-delay: 0s;animation-delay: 0s;}
   .pricing-cta .delay2 {-webkit-animation-delay: 0.4s;animation-delay: 0.4s;}
   .pricing-cta .delay3 {-webkit-animation-delay: 0.8s;animation-delay: 0.8s;}
  
  
   @media only screen and (max-width: 767px) {
    .faq-wrapper { padding: 30px 0 60px 0;}
    .faq-wrapper .heading{margin: 0 0 30px 0;}
  }


  
  @-webkit-keyframes slide { 
  0% { opacity:0; transform: translateX(-10px) rotate(45deg); }  
  20% { opacity:1; transform: translateX(-7px) rotate(45deg); } 
  50%{opacity: 1;}
  80% { opacity:1; transform: translateX(7px) rotate(45deg); }  
  100% { opacity:0; transform: translateX(10px) rotate(45deg); } 
  } 
  @keyframes slide {
  0% { opacity:0; transform: translateX(-10px) rotate(45deg); }  
  20% { opacity:1; transform: translateX(-7px) rotate(45deg); } 
  50%{opacity: 1;}
  80% { opacity:1; transform: translateX(7px) rotate(45deg); }  
  100% { opacity:0; transform: translateX(10px) rotate(45deg); } 
  }
  
  /* accordion */

/* Features Section */
.features {
  padding: 100px 0;
  background: #f4f8ff;
}

.features .list-wrap {
  display: flex;
  margin: 0 -18px;
  flex-wrap: wrap;
  justify-content: center;
}
.features .list-wrap .list-item {
  flex: 1 0 auto;
  width: 100%;
  max-width: 33.3333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0 18px;
  margin: 0 0 20px 0;
}

.features .list-wrap .list-item .title {
  font-size: 2rem;
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 10px;
  color: #2f7c72;
}
.features .list-wrap .list-item .desc {
  font-size: 1.8rem;
  line-height: 1.7;
  text-align: center;
}
@media only screen and (max-width: 1080px) {
  .features .list-wrap .list-item {
    max-width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .features {
    padding: 60px 0;
  }
  .features .list-wrap .list-item {
    max-width: 100%;
  }
  .features .list-wrap .list-item .title {
    font-size: 1.6rem;
  }
  .features .list-wrap .list-item .desc {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}
/* Features Section */

/* Cars Section */
.cars {
  padding: 80px 0;
}
.cars .container {
  max-width: 1100px;
}
.cars #controls {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: -50px;
}
.cars .slider-control {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}
.cars .slider-control:hover {
  border-color: #529471;
}
.cars .slider-control svg {
  width: 80%;
}
.cars .tns-outer {
  margin: 0 auto;
}
.cars .img-wrap{max-width: 600px;margin: 0 auto;}
.cars .slide .title {
  font-size: 4.2rem;
  line-height: 1.5;
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .cars {
    padding: 60px 0;
  }
  .cars .sub-heading {
    margin-bottom: 0;
  }
  .cars #controls {
    margin-bottom: 0;
    display: none;
  }
  .cars .slide .title {
    font-size: 2rem;
  }
  .cars .tns-outer{margin: 24px auto 0 auto;}
}
/* Cars Section */

/* about Section */
.about {
  background: #40413c;
  padding: 80px 0;
  color: #fff;
  margin: 0;
}
.about .heading {
  margin-bottom: 40px;
}
.about .content-wrap {
  display: flex;
  gap: 20px;
  width: 100%;
}
.about .content-wrap .left-wrap,
.about .content-wrap .right-wrap {
  max-width: 50%;
}
.about .left-wrap {
  border-radius: 20px;
  overflow: hidden;
}
.about .right-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about .right-wrap .title {
  font-size: 3.6rem;
  line-height: 1.2;
  margin-bottom: 30px;
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.about .right-wrap .desc {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 400;
}

@media only screen and (max-width: 1080px) {
  .about {
    padding: 60px 0;
  }
  .about .content-wrap {
    flex-direction: column;
  }

  .about .content-wrap {
    max-width: 800px;
    margin: 0 auto;
  }
  .about .content-wrap .left-wrap,
  .about .content-wrap .right-wrap {
    /* flex: 1 0 auto; */
    max-width: 100%;
  }

  .about .right-wrap {
    padding: 0;
  }
  .about .right-wrap .title {
    font-size: 2.6rem;
    margin-bottom: 20px;
  }
  .about .right-wrap .desc {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
  }
}
/* about Section */

/* Footer */
footer {
  background-color: #f3f7ec;
  padding: 80px 0 40px 0;
}
footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .img-wrap {
  width: 320px;
  margin-top: -20px;
}

footer .right-wrap li {
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 10px;
}
footer .right-wrap li a:hover {
  text-decoration: underline;
}
footer .copyright {
  margin-top: 40px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  footer {
    padding: 20px 0;
  }
  footer .container {
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  footer .img-wrap {
    width: 150px;
    margin: 0 auto;
  }
  footer .right-wrap li {
    text-align: center;
  }
  footer .copyright {
    font-size: 1.4rem;
  }
}
